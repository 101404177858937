body, div, dl, dt, dd, ul, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img, abbr {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal; }

ul li {
  list-style: none; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal; }

sup {
  vertical-align: text-top; }

sub {
  vertical-align: text-bottom; }

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

legend {
  color: #000; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block; }

img {
  max-width: 100%;
  height: auto; }

.icon-site-sprite, .icon-site-arrow-left, .icon-site-arrow-line, .icon-site-arrow-right, .icon-site-arrow, .icon-site-commerc, .icon-site-haus, .icon-site-hexagon-small, .icon-site-hexagon, .icon-site-line, .icon-site-logo, .icon-site-parking, .icon-site-promicom, .icon-site-room {
  background-image: url("/images/icon-site-s580352204c.png");
  background-repeat: no-repeat; }

[class^="icon-site"] {
  display: inline-block; }

body, html {
  font-family: PT_Sans-Caption-Web-Regular;
  height: 100%;
  font-size: 14px; }

a {
  outline: none; }

sup {
  top: -5px;
  line-height: 1; }

.ghost-footer {
  height: 350px; }

.wrapper {
  min-height: 100%;
  margin-bottom: -350px;
  overflow: hidden; }

.errorMessage, .help-block {
  color: red;
  font-size: 12px; }

footer {
  height: auto; }
  footer #contacts-form .col-xs-6 {
    padding: 0;
    width: 70%;
    display: block;
    margin: 0 auto; }

.text {
  line-height: 1.4em;
  text-align: justify; }
  .text ul {
    margin-bottom: 10px; }
    .text ul li {
      list-style: square outside;
      padding-left: 15px; }
  .text p {
    margin-bottom: 10px; }
  .text strong, .text b {
    font-weight: bold; }
  .text h1 {
    font-size: 32px; }
  .text h2 {
    font-size: 24px; }
  .text h3 {
    font-size: 18px; }
  .text h4 {
    font-size: 14px; }
  .text h5 {
    font-size: 12px; }
  .text h6 {
    font-size: 10px; }
  .text small {
    font-size: 10px; }
  .text i {
    font-style: italic; }

.ui-autocomplete .ui-menu-item strong {
  font-weight: bold; }

@font-face {
  font-family: BanderaPro;
  src: url("/fonts/BanderaPro.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaPro.woff") format("woff"), url("/fonts/BanderaPro.ttf") format("truetype"), url("/fonts/BanderaPro.svg#BanderaPro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: BanderaPro-Bold;
  src: url("/fonts/BanderaPro-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaPro-Bold.woff") format("woff"), url("/fonts/BanderaPro-Bold.ttf") format("truetype"), url("/fonts/BanderaPro-Bold.svg#BanderaPro-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: BanderaPro-BoldItalic;
  src: url("/fonts/BanderaPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaPro-BoldItalic.woff") format("woff"), url("/fonts/BanderaPro-BoldItalic.ttf") format("truetype"), url("/fonts/BanderaPro-BoldItalic.svg#BanderaPro-BoldItalic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: BanderaProHeavy;
  src: url("/fonts/BanderaProHeavy.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaProHeavy.woff") format("woff"), url("/fonts/BanderaProHeavy.ttf") format("truetype"), url("/fonts/BanderaProHeavy.svg#BanderaProHeavy") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: BanderaProHeavy-Italic;
  src: url("/fonts/BanderaProHeavy-Italic.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaProHeavy-Italic.woff") format("woff"), url("/fonts/BanderaProHeavy-Italic.ttf") format("truetype"), url("/fonts/BanderaProHeavy-Italic.svg#BanderaProHeavy-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: BanderaPro-Italic;
  src: url("/fonts/BanderaPro-Italic.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaPro-Italic.woff") format("woff"), url("/fonts/BanderaPro-Italic.ttf") format("truetype"), url("/fonts/BanderaPro-Italic.svg#BanderaPro-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: BanderaProLight;
  src: url("/fonts/BanderaProLight.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaProLight.woff") format("woff"), url("/fonts/BanderaProLight.ttf") format("truetype"), url("/fonts/BanderaProLight.svg#BanderaProLight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: BanderaProLight-Italic;
  src: url("/fonts/BanderaProLight-Italic.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaProLight-Italic.woff") format("woff"), url("/fonts/BanderaProLight-Italic.ttf") format("truetype"), url("/fonts/BanderaProLight-Italic.svg#BanderaProLight-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: BanderaProMedium;
  src: url("/fonts/BanderaProMedium.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaProMedium.woff") format("woff"), url("/fonts/BanderaProMedium.ttf") format("truetype"), url("/fonts/BanderaProMedium.svg#BanderaProMedium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: BanderaProMedium-Italic;
  src: url("/fonts/BanderaProMedium-Italic.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaProMedium-Italic.woff") format("woff"), url("/fonts/BanderaProMedium-Italic.ttf") format("truetype"), url("/fonts/BanderaProMedium-Italic.svg#BanderaProMedium-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: BanderaProThin;
  src: url("/fonts/BanderaProThin.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaProThin.woff") format("woff"), url("/fonts/BanderaProThin.ttf") format("truetype"), url("/fonts/BanderaProThin.svg#BanderaProThin") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: BanderaProThin-Italic;
  src: url("/fonts/BanderaProThin-Italic.eot?#iefix") format("embedded-opentype"), url("/fonts/BanderaProThin-Italic.woff") format("woff"), url("/fonts/BanderaProThin-Italic.ttf") format("truetype"), url("/fonts/BanderaProThin-Italic.svg#BanderaProThin-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: PT_Sans-Caption-Web-Bold;
  src: url("/fonts/PT_Sans-Caption-Web-Bold.eot?#iefix") format("embedded-opentype"), url("/fonts/PT_Sans-Caption-Web-Bold.woff") format("woff"), url("/fonts/PT_Sans-Caption-Web-Bold.ttf") format("truetype"), url("/fonts/PT_Sans-Caption-Web-Bold.svg#PT_Sans-Caption-Web-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: PT_Sans-Caption-Web-Regular;
  src: url("/fonts/PT_Sans-Caption-Web-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/PT_Sans-Caption-Web-Regular.woff") format("woff"), url("/fonts/PT_Sans-Caption-Web-Regular.ttf") format("truetype"), url("/fonts/PT_Sans-Caption-Web-Regular.svg#PT_Sans-Caption-Web-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

.no-row-padding {
  padding-left: 0;
  padding-right: 0; }

#toTop {
  border: 1px solid #face00;
  bottom: 80px;
  color: #face00;
  cursor: pointer;
  display: none;
  font-size: 44px;
  padding: 5px 10px 7px;
  position: fixed;
  right: 20px;
  z-index: 10; }
  #toTop:hover {
    background: #face00;
    color: #ffffff; }

header {
  color: #ffffff;
  padding-top: 20px; }
  header .time-work-block {
    position: relative;
    border-left: 1px solid #ffffff;
    padding-bottom: 14px;
    padding-right: 0; }
  header .css-arrow-right {
    position: absolute;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #ffffff;
    border-top: 6px solid transparent;
    height: 0;
    width: 0;
    top: 31px;
    left: 0; }
  header .phone {
    font-size: 24px; }
    header .phone a {
      color: #ffffff; }
  header .border-button {
    margin-top: 25px; }
  header .time-work {
    font-size: 16px;
    margin-top: 6px; }
    header .time-work span {
      display: inline-block;
      margin-bottom: 29px; }
  header .icon-site-logo {
    background-position: 0 -440px;
    height: 103px;
    width: 477px; }
  header .header-box a {
    width: 100%; }
  header .header-box svg {
    width: 100%;
    height: 120px; }

.border-button {
  border: 1px solid #face00;
  position: relative;
  height: 40px; }
  .border-button button {
    position: absolute;
    top: -7px;
    background: none;
    border: 2px solid #ffffff;
    left: 6px;
    width: 100%;
    text-transform: uppercase;
    border-radius: 0; }
    .border-button button:hover, .border-button button:focus {
      border-color: #face00;
      color: #ffffff;
      background: #face00; }

.first-slide .orange-block {
  background: #face00;
  opacity: 0.7;
  position: absolute; }

.first-slide .content {
  font-family: BanderaProMedium;
  font-size: 34px;
  color: #ffffff;
  line-height: 60px;
  margin-top: 37px;
  position: relative; }

.form-slide {
  padding-bottom: 50px; }
  .form-slide .form-background {
    background: #face00;
    width: 415px;
    height: 400px;
    position: absolute;
    margin-top: -410px; }
  .form-slide .form {
    background: #ffffff;
    padding: 40px 30px;
    margin-top: -430px;
    position: relative;
    z-index: 1; }
    .form-slide .form .title {
      font-family: BanderaProMedium;
      color: #383232;
      font-size: 22px;
      text-align: center; }
      .form-slide .form .title:after {
        margin-top: 25px;
        width: 150px;
        height: 5px;
        background: #0267c1;
        display: inline-block;
        content: " "; }
  .form-slide .description {
    text-align: center;
    margin-top: 25px;
    font-size: 18px;
    line-height: 28px; }
    .form-slide .description span {
      background: #face00;
      padding: 0 5px; }
  .form-slide form {
    margin-top: 50px; }
  .form-slide .form-group {
    margin-bottom: 10px; }

.about-company .title {
  font-family: BanderaProMedium;
  color: #383232;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 60px; }

.about-company .numbers {
  text-align: center;
  font-size: 18px;
  line-height: 1; }
  .about-company .numbers .prev {
    font-size: 20px;
    color: #face00;
    position: absolute;
    top: 10px;
    left: 90px; }
  .about-company .numbers .item {
    width: 210px;
    display: inline-block;
    position: relative; }
  .about-company .numbers .item-num {
    font-size: 20px;
    color: #face00;
    display: inline-block;
    margin-bottom: 40px;
    padding-top: 28px; }
    .about-company .numbers .item-num span {
      font-family: BanderaProMedium;
      font-size: 80px; }
  .about-company .numbers .num-1 {
    background: url("/images/about/1.png") no-repeat;
    width: 163px;
    height: 167px; }
  .about-company .numbers .num-2 {
    background: url("/images/about/2.png") no-repeat;
    width: 175px;
    height: 173px; }
  .about-company .numbers .num-3 {
    background: url("/images/about/3.png") no-repeat;
    width: 172px;
    height: 172px; }
  .about-company .numbers .num-4 {
    background: url("/images/about/4.png") no-repeat;
    width: 168px;
    height: 169px; }
  .about-company .numbers .num-5 {
    background: url("/images/about/5.png") no-repeat;
    width: 171px;
    height: 167px; }

.steps-title {
  font-family: BanderaProMedium;
  text-align: center;
  position: relative;
  left: -135px;
  top: 105px; }
  .steps-title .count {
    color: #face00;
    font-size: 140px;
    display: inline-block; }
  .steps-title .title {
    text-align: left;
    display: inline-block;
    font-size: 30px; }
    .steps-title .title .big {
      margin-top: 15px;
      text-transform: uppercase;
      font-size: 40px; }
      .steps-title .title .big span {
        color: #0267c1; }

.two-slide {
  height: 1200px;
  margin: -566px auto 0;
  max-width: 1920px;
  overflow: hidden;
  position: relative; }
  .two-slide .two-slide-wrap {
    display: table;
    width: 100%; }
  .two-slide .slide {
    position: relative;
    display: table-cell; }
  .two-slide .button-wrap {
    z-index: 1;
    color: #ffffff;
    margin-left: -80px;
    position: absolute;
    bottom: 130px;
    width: 100%; }
    .two-slide .button-wrap .border-button {
      width: 210px;
      margin: 0 auto; }

.two-slide-1 {
  background: url("/images/two-slide/1.jpg") no-repeat scroll center center;
  width: 300px;
  height: 1200px;
  /*float: left;*/
  transition: all 0.5s ease; }
  .two-slide-1 .slide-content {
    width: 204px; }

.two-slide-2 {
  background: url("/images/two-slide/2.jpg") no-repeat scroll center center;
  width: 300px;
  height: 1200px;
  /*float: left;*/
  transition: all 0.5s ease; }

.two-slide-3 {
  background: url("/images/two-slide/3.jpg") no-repeat scroll center center;
  width: 290px;
  height: 1200px;
  /*float: left;*/
  transition: all 0.5s ease; }

.two-slide-4 {
  background: url("/images/two-slide/4.jpg") no-repeat scroll center center;
  width: 290px;
  height: 1200px;
  /*float: left;*/
  transition: all 0.5s ease; }

.two-slide-5 {
  background: url("/images/two-slide/5.jpg") no-repeat scroll center center;
  width: 290px;
  height: 1200px;
  /*float: left;*/
  transition: all 0.5s ease; }

.two-slide-6 {
  background: url("/images/two-slide/6.jpg") no-repeat scroll center center;
  width: 280px;
  height: 1200px;
  /*float: left;*/
  transition: all 0.5s ease; }

.slide-content {
  position: absolute;
  color: #ffffff;
  font-size: 17px;
  line-height: 24px; }
  .slide-content .title .num {
    color: #face00;
    font-family: BanderaProHeavy;
    font-size: 80px;
    display: inline-block; }
  .slide-content .title .desc {
    font-family: BanderaProMedium;
    display: inline-block;
    font-size: 20px;
    line-height: 26px; }
  .slide-content .description {
    margin-top: 45px; }

.causes {
  position: relative;
  z-index: 1;
  top: 570px;
  width: 1050px;
  color: #ffffff;
  font-size: 18px;
  line-height: 24px; }
  .causes .item {
    margin-bottom: 20px;
    width: 600px;
    clear: both; }
    .causes .item .description {
      padding-top: 15px;
      padding-left: 57px;
      width: 570px; }
  .causes .num {
    line-height: 70px;
    font-size: 70px;
    color: #face00;
    display: inline-block;
    float: left;
    margin-right: 15px; }
  .causes .title-block {
    position: absolute;
    right: 0;
    top: 100px;
    font-family: BanderaProMedium;
    font-size: 22px; }
    .causes .title-block .num {
      font-size: 640px; }
    .causes .title-block .big {
      margin-left: 35px;
      font-size: 70px;
      text-transform: uppercase;
      margin-bottom: 14px; }
    .causes .title-block .desc {
      margin-left: 40px; }

.main-title {
  font-family: BanderaProMedium;
  text-align: center;
  font-size: 30px;
  color: #383232;
  text-transform: uppercase;
  margin-bottom: 55px; }
  .main-title:after {
    margin: 25px auto;
    width: 150px;
    height: 5px;
    background: #0267c1;
    display: block;
    content: " "; }

.who {
  position: absolute;
  width: 100%;
  z-index: 2;
  text-align: center;
  font-size: 18px;
  color: #383232; }
  .who .icon {
    margin-top: 75px;
    margin-bottom: 40px; }

.whe-are-working {
  height: 470px;
  position: absolute;
  width: 100%;
  margin-top: 560px; }
  .whe-are-working .main-title {
    margin-top: 65px;
    margin-bottom: 50px; }
  .whe-are-working .items.queue-1 {
    height: 200px;
    position: relative; }
  .whe-are-working .items {
    position: relative; }
  .whe-are-working .item {
    position: absolute; }
    .whe-are-working .item .num {
      font-size: 40px;
      color: #face00;
      font-family: BanderaPro;
      display: inline-block;
      float: left;
      margin-right: 15px; }
    .whe-are-working .item .description {
      font-size: 18px;
      line-height: 24px;
      padding-left: 63px;
      width: 280px; }
  .whe-are-working .line-block {
    position: absolute; }
    .whe-are-working .line-block .line {
      margin-top: 100px; }
    .whe-are-working .line-block .items {
      margin-top: -27px; }

.default-form form input, .default-form form button {
  text-transform: uppercase;
  height: 40px;
  border-radius: 0;
  text-align: center;
  width: 100%; }

.default-form form .btn-primary {
  background: #0267c1; }

.default-form form button:hover, .default-form form button:focus {
  background: #065296; }

footer {
  padding: 25px 0 0;
  background: #1e1d1d; }
  footer .answer-info {
    font-family: BanderaProMedium;
    font-size: 22px;
    line-height: 26px;
    color: #ffffff; }
  footer .copyright {
    padding-top: 20px;
    height: 55px;
    background: #face00; }
    footer .copyright a {
      color: #333333; }
  footer .message-info {
    color: #9e9595;
    line-height: 20px; }
  footer .line {
    overflow: hidden;
    position: absolute;
    left: 75px; }
  footer .contact-block {
    margin-top: 50px;
    margin-bottom: 30px; }
    footer .contact-block .icon {
      margin-top: 5px;
      color: #face00;
      float: left;
      width: 20px; }
    footer .contact-block .info {
      float: left;
      color: #9e9595;
      line-height: 20px; }
      footer .contact-block .info a {
        color: #9e9595; }
    footer .contact-block svg {
      width: 100%;
      display: block;
      height: 100px; }

.calc {
  margin-bottom: 45px; }
  .calc .calc-block {
    position: relative;
    width: 570px;
    margin: 0 auto;
    border: 1px solid #b3b3b3;
    border-radius: 30px; }
    .calc .calc-block .wrap {
      padding: 0 25px; }
    .calc .calc-block .info {
      text-align: center;
      font-size: 18px;
      line-height: 26px;
      margin: 15px 0 25px; }
      .calc .calc-block .info span {
        text-transform: uppercase;
        background: #face00;
        padding: 0 5px; }
    .calc .calc-block .message {
      margin: 5px 0 15px;
      text-align: center; }
  .calc .calc-title {
    position: relative;
    width: 570px;
    margin: 0 auto 50px;
    text-transform: uppercase;
    font-family: BanderaProMedium;
    font-size: 30px; }
    .calc .calc-title .big {
      margin-top: 20px;
      font-size: 40px; }
      .calc .calc-title .big span {
        color: #0267c1; }
    .calc .calc-title .arrow-block {
      position: absolute;
      left: 300px;
      top: 70px; }
  .calc .items {
    position: relative;
    display: inline-block;
    border-bottom: 1px solid #b3b3b3;
    border-radius: 30px; }
    .calc .items .item {
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      padding: 20px 29px;
      border-radius: 30px;
      float: left; }
      .calc .items .item.active {
        background: #face00;
        color: #ffffff; }
  .calc .block .title {
    font-size: 18px;
    margin: 30px 0 25px; }

.ui-widget.ui-widget-content {
  border: none;
  background: #b3b3b3;
  height: 3px; }

.ui-slider-handle.ui-corner-all.ui-state-default {
  background: url("/images/icon-site/hexagon-small.png");
  border: none;
  width: 26px;
  height: 23px;
  margin-top: -6px; }

.ui-widget-header {
  background: #0267c1; }

.view-val {
  display: inline-block;
  position: relative;
  font-size: 18px;
  margin-bottom: 20px;
  color: #0267c1;
  margin-left: -17px; }
  .view-val sub {
    font-size: 12px;
    margin-top: -10px;
    display: inline-block; }

.reviews {
  margin-bottom: 70px; }
  .reviews img {
    max-width: none;
    height: auto; }
  .reviews .icon-site-hexagon {
    font-size: 16px;
    font-family: BanderaPro-Italic;
    color: #ffffff;
    text-align: center;
    padding-top: 22px;
    position: absolute;
    top: 130px;
    left: -40px; }
  .reviews .video {
    margin-top: 30px; }
  .reviews .items .item + .item {
    margin-top: 100px; }
  .reviews .items .item .col-xs-7 {
    padding-bottom: 15px;
    border-bottom: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9; }
  .reviews .items .item .col-xs-3 {
    border-top: 1px solid #d9d9d9; }
  .reviews .items .item.left .col-xs-7 {
    border-right: 0;
    border-left: 1px solid #d9d9d9; }
  .reviews .items .description {
    font-size: 18px;
    color: #0267c1;
    line-height: 26px;
    font-family: BanderaPro-Italic;
    margin: 35px 0 15px; }
  .reviews .items .title {
    font-family: BanderaProMedium;
    font-size: 22px;
    line-height: 30px; }
  .reviews .items .text {
    padding-top: 15px;
    margin-left: 40px;
    font-size: 16px; }
  .reviews .items .image {
    position: relative; }
    .reviews .items .image.image-first {
      margin-left: -75px;
      margin-top: -55px; }
    .reviews .items .image.image-two {
      margin-top: -100px;
      position: relative;
      z-index: 1; }
    .reviews .items .image.right .icon-site-hexagon {
      left: auto;
      right: -70px; }
    .reviews .items .image.right.image-first {
      margin-left: 55px; }
      .reviews .items .image.right.image-first .icon-site-hexagon {
        left: auto;
        right: -130px; }

.whe-projects {
  margin-bottom: 60px;
  margin-top: -80px; }

.slider-images {
  width: 100%;
  margin: 0 auto;
  text-align: center; }
  .slider-images .description {
    font-family: BanderaProMedium;
    font-size: 18px;
    background: #face00;
    display: inline-block;
    padding: 20px 60px;
    margin-top: -40px; }

.calc-bg {
  position: absolute; }
  .calc-bg .calc-bg-image-1 {
    background: url("/images/calc-bg-1.png");
    width: 579px;
    height: 380px;
    position: absolute;
    left: -50px;
    top: 90px; }
  .calc-bg .calc-bg-image-2 {
    background: url("/images/calc-bg-2.png");
    width: 558px;
    height: 384px;
    position: absolute;
    left: 590px;
    top: 70px; }
  .calc-bg .calc-bg-block {
    background: #ffffff;
    opacity: 0.7;
    width: 570px;
    height: 380px;
    position: absolute;
    left: 286px;
    top: 60px; }
  .calc-bg.calc-2 .calc-bg-block {
    left: 285px; }

.slick-slide img {
  width: 100%; }

.slick-prev::before, .slick-next::before {
  content: ""; }

.slick-prev:after, .slick-next:after {
  content: "";
  width: 46px;
  height: 90px;
  opacity: 0.38;
  position: absolute; }

.slick-prev i, .slick-next i {
  position: relative;
  z-index: 1; }

.slick-prev {
  left: 7px; }
  .slick-prev:after {
    background: url("/images/bg-arrow-left.png") no-repeat;
    left: -8px;
    top: -30px; }

.slick-next {
  right: 7px; }
  .slick-next:after {
    background: url("/images/bg-arrow-right.png") no-repeat;
    right: -8px;
    top: -30px; }

#modalForm {
  padding-bottom: 50px; }
  #modalForm .form-background {
    background: #face00;
    width: 415px;
    height: 400px;
    position: absolute;
    margin-top: 10px; }
  #modalForm .modal-dialog {
    width: 400px; }
  #modalForm .form {
    background: #ffffff;
    padding: 40px 30px;
    position: relative;
    z-index: 1; }
    #modalForm .form .title {
      font-family: BanderaProMedium;
      color: #383232;
      font-size: 22px;
      text-align: center; }
      #modalForm .form .title:after {
        margin-top: 25px;
        width: 150px;
        height: 5px;
        background: #0267c1;
        display: inline-block;
        content: " "; }
  #modalForm .description {
    text-align: center;
    margin-top: 25px;
    font-size: 18px;
    line-height: 28px; }
  #modalForm form {
    margin-top: 50px; }
  #modalForm .form-group {
    margin-bottom: 10px; }

button.close {
  position: relative;
  z-index: 2;
  right: 10px; }

.bg-fixed-1 {
  background: url("/images/first-block.jpg") no-repeat 50% 0;
  height: 970px;
  margin: 0 auto;
  background-attachment: fixed; }

.bg-fixed-2 {
  background-image: url("/images/third-block.jpg");
  height: 1260px;
  margin: -566px auto 0;
  position: relative;
  background-attachment: fixed;
  background-position: 50% 50%;
  width: 100%; }

@media screen and (max-width: 1000px) {
  header .phone {
    font-size: 27px; } }

@media (max-width: 1199px) {
  sup {
    top: 6px;
    font-size: 16px; }
  .container {
    width: 100%;
    min-width: 320px;
    max-width: 100%; }
  header .row {
    display: flex;
    justify-content: space-between; }
    header .row .col-xs-5, header .row .col-xs-5, header .row .col-xs-offset-2 {
      width: auto;
      margin: 0;
      float: none; }
  .form-slide {
    padding: 0 15px; }
    .form-slide .form-background {
      background: transparent;
      display: none; }
  .steps-title {
    top: 50px; }
  .about-company .numbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; }
    .about-company .numbers .item {
      margin-bottom: 25px; }
    .about-company .numbers .item-num {
      font-size: 16px;
      padding-top: 0;
      margin: 0 auto 10px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .about-company .numbers .item-num span {
        font-size: 50px; }
  .two-slide-1, .two-slide-2, .two-slide-3, .two-slide-4, .two-slide-5, .two-slide-6 {
    width: 16.66%;
    padding: 15px; }
    .two-slide-1 .slide-content, .two-slide-2 .slide-content, .two-slide-3 .slide-content, .two-slide-4 .slide-content, .two-slide-5 .slide-content, .two-slide-6 .slide-content {
      width: auto; }
  .calc-bg {
    position: relative; }
    .calc-bg .calc-bg-block {
      left: 50%;
      margin-left: -285px; }
  .slick-slide img {
    width: 100% !important; }
  .reviews img {
    width: 100%;
    max-width: 100%; }
  .reviews .row {
    margin: 0; }
    .reviews .row .col-xs-12 {
      padding: 0; }
  .reviews .icon-site-hexagon {
    top: -31px;
    left: 0; }
  .reviews .reviews-box {
    display: flex;
    align-items: center; }
    .reviews .reviews-box .col-xs-3, .reviews .reviews-box .col-xs-offset-1, .reviews .reviews-box .col-xs-7 {
      width: 50%;
      margin: 0; }
    .reviews .reviews-box .col-xs-3 {
      width: 30%; }
    .reviews .reviews-box .col-xs-7 {
      width: 70%; }
  .reviews .items .image.image-first {
    margin: 0; }
  .reviews .items .image.right .icon-site-hexagon {
    right: 0;
    top: -31px; }
  .reviews .items .image.right.image-first {
    margin: 0; }
    .reviews .items .image.right.image-first .icon-site-hexagon {
      right: 0px;
      top: -31px; }
  .reviews .items .item .col-xs-3 {
    border: none; }
  .reviews .items .text {
    margin: 0; }
  .reviews .items .item + .item {
    margin-top: 40px;
    margin-bottom: 40px; } }

@media (max-width: 1061px) {
  .two-block-part-top, .two-block-part-bottom {
    height: auto !important;
    background: none !important;
    margin: 0 !important; }
  .steps-title {
    top: 0;
    left: 0; }
  .two-slide {
    max-width: 100%;
    margin: 0;
    height: auto; }
    .two-slide .two-slide-wrap {
      display: flex;
      flex-wrap: wrap; }
      .two-slide .two-slide-wrap .slide {
        height: 300px;
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .two-slide .two-slide-wrap .slide .slide-content {
          top: auto !important;
          left: auto !important;
          right: auto !important;
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;
          flex-direction: column; }
          .two-slide .two-slide-wrap .slide .slide-content .title {
            display: flex;
            align-items: center; }
            .two-slide .two-slide-wrap .slide .slide-content .title .num {
              margin: 0 15px;
              display: block; }
    .two-slide .two-slide-3 {
      background: url("/images/two-slide/3.jpg") no-repeat scroll center 82%; }
    .two-slide .button-wrap {
      position: relative;
      margin: 30px 0;
      color: #face00;
      bottom: 0; }
      .two-slide .button-wrap .btn {
        border-color: #face00; }
  .bg-fixed-image .col-xs-12 {
    padding: 0; }
  .causes {
    width: 100%; }
  .whe-are-working .main-title {
    margin: 35px 0; }
  .whe-are-working .line-block {
    display: none; }
  .whe-are-working .items {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 auto; }
  .whe-are-working .item {
    width: auto;
    position: relative;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    padding: 0;
    float: none;
    display: flex;
    align-items: center; }
    .whe-are-working .item .description {
      width: auto;
      padding: 0; }
  .whe-projects {
    margin-top: 0; } }

@media (max-width: 992px) {
  header .header-box {
    flex-direction: column; }
    header .header-box .col-xs-5, header .header-box .col-xs-5, header .header-box .col-xs-offset-2 {
      width: auto;
      margin: 0;
      float: none; }
      header .header-box .col-xs-5 a, header .header-box .col-xs-5 a, header .header-box .col-xs-offset-2 a {
        display: flex;
        justify-content: center; }
  header .info-box .row {
    align-items: center; }
    header .info-box .row .time-work-block, header .info-box .row .col-xs-5, header .info-box .row .col-xs-7 {
      width: 50%;
      order: 1; }
    header .info-box .row .col-xs-7 {
      order: 2; }
    header .info-box .row .time-work-block {
      border: none;
      padding-bottom: 0; }
      header .info-box .row .time-work-block .css-arrow-right {
        display: none; }
      header .info-box .row .time-work-block .time-work {
        margin: 0;
        text-align: center;
        font-size: 18px; }
        header .info-box .row .time-work-block .time-work span {
          margin: 0; }
  .form-slide .form-box-slide {
    width: 50%;
    margin: 0 auto;
    float: none; }
  .form-slide .form .title:after {
    margin: 15px auto;
    display: block; }
  .causes .title-block {
    right: 25px; }
    .causes .title-block .num {
      text-align: center;
      width: 100%;
      font-size: 500px; }
  .causes .item {
    margin-left: 25px !important;
    width: 55%; }
    .causes .item .description {
      width: 100%; }
  .third-block-part-bottom {
    margin: -570px auto 0 !important; } }

@media (max-width: 800px) {
  .two-slide .two-slide-wrap .slide {
    width: 50%;
    background-size: cover; }
  .who .container, .who .col-xs-12 {
    padding: 0; }
  .who .row {
    margin: 0;
    padding: 0; }
    .who .row.line-box {
      display: flex; }
      .who .row.line-box .col-xs-3 {
        width: 50%; }
  .reviews .reviews-box .col-xs-3, .reviews .reviews-box .col-xs-offset-1, .reviews .reviews-box .col-xs-7 {
    width: 50%;
    margin: 0; }
  .whe-are-working .items {
    width: 75%; }
  .whe-are-working .col-xs-10 {
    width: 100%; }
  .whe-are-working .col-xs-offset-1 {
    margin: 0; }
  footer {
    height: auto; }
    footer .row {
      margin: 0; }
    footer .answer-info, footer .default-form {
      width: 50%;
      margin: 0; }
    footer .line {
      display: none; }
    footer .contact-block {
      margin-top: 0; }
      footer .contact-block .icon {
        margin: 0; }
      footer .contact-block a {
        display: flex;
        justify-content: center; }
      footer .contact-block .col-xs-5, footer .contact-block .col-xs-7, footer .contact-block .col-xs-4, footer .contact-block .col-xs-3 {
        width: 100%;
        margin: 0; }
      footer .contact-block .phone-num, footer .contact-block .col-xs-4, footer .contact-block .col-xs-3 {
        display: flex;
        justify-content: center;
        align-items: center; }
      footer .contact-block .row.phone-num {
        margin-bottom: 20px;
        flex-direction: column; }
    footer #contacts-form .col-xs-6 {
      padding: 0;
      width: 100%; } }

@media (max-width: 750px) {
  .main-title {
    font-size: 22px; }
  .calc .calc-title {
    font-size: 22px; }
    .calc .calc-title .big {
      margin-top: 10px;
      font-size: 25px; }
  #modalForm .form-background {
    display: none; }
  #modalForm .form-horizontal .form-group {
    margin-bottom: 10px; }
  #modalForm .form {
    padding: 15px; }
  #modalForm .modal-dialog {
    width: 290px;
    margin: 35px auto; }
  .causes {
    font-size: 16px; }
    .causes .title-block {
      right: 5px; }
      .causes .title-block .big {
        font-size: 55px;
        text-align: center;
        margin-left: 0; }
      .causes .title-block .desc {
        margin-left: 0;
        text-align: center; }
      .causes .title-block .num {
        text-align: center;
        width: 100%;
        font-size: 450px; }
    .causes .item {
      margin-left: 5px !important;
      width: 55%; }
      .causes .item .description {
        width: 100%; }
  .third-block-part-bottom {
    margin: -570px auto 0 !important; } }

@media (max-width: 700px) {
  .text iframe {
    width: 100% !important; }
  .whe-are-working .items {
    width: 100%; }
  .form-slide .form-box-slide {
    width: 75%; }
  .who {
    position: relative; }
    .who .line-box {
      display: flex;
      flex-wrap: wrap; }
      .who .line-box .bg-img {
        background-size: cover !important;
        background-position: 50% 50% !important; }
      .who .line-box .col-xs-3 {
        width: 50%; }
  .third-block-part-top {
    height: auto !important;
    padding-bottom: 180px; }
  .causes {
    top: 390px;
    display: flex;
    flex-direction: column; }
    .causes .items {
      order: 2; }
    .causes .title-block {
      order: 1;
      position: relative;
      top: -55px;
      align-self: flex-end; }
      .causes .title-block .big {
        font-size: 40px; }
      .causes .title-block .num {
        font-size: 350px; }
    .causes .item {
      width: 100%;
      display: flex;
      align-items: center; }
      .causes .item .num {
        font-size: 50px;
        line-height: 40px; }
      .causes .item .description {
        padding-top: 0px;
        padding-left: 0px; }
  @-moz-document url-prefix() {
    .causes {
      top: 175px; } } }

@media (max-width: 600px) {
  .reviews .items .item.left .col-xs-7 {
    border: none; }
  .first-slide .orange-block {
    display: none; }
  .calc .calc-title {
    width: 100%;
    text-align: center;
    margin: 0 auto 20px; }
  .calc .calc-block {
    width: 100%; }
    .calc .calc-block .items {
      display: flex;
      justify-content: space-between;
      flex-direction: column; }
      .calc .calc-block .items .item {
        padding: 15px;
        float: none;
        display: flex;
        justify-content: center;
        align-items: center; }
    .calc .calc-block .col-xs-6 {
      width: 100%;
      float: none;
      padding: 0; }
  .form-horizontal .form-group {
    margin: 0 0 10px 0; }
  .calc-bg .calc-bg-block {
    height: 580px; }
  .reviews .reviews-box {
    flex-direction: column; }
  .reviews .items .item.left {
    border: none;
    padding: 0;
    width: 100%;
    margin: 0; }
  .reviews .items .item .col-xs-7, .reviews .items .item .col-xs-3 {
    border: none;
    padding: 0;
    width: 100%;
    margin: 0; }
  .who .icon {
    margin: 20px 0; }
  .who .line-box .bg-img {
    height: 200px !important; }
  .who .line-box .col-xs-3 {
    width: 50%; }
  .whe-projects iframe {
    width: 100% !important; }
  footer .answer-info, footer .default-form, footer .col-xs-6 {
    width: 100%;
    padding: 0; }
  footer .answer-info, footer .message-info {
    margin-bottom: 25px;
    text-align: center; }
  footer .footer-title {
    display: flex;
    flex-direction: column; }
  footer .copyright {
    height: auto;
    padding: 20px 0; }
    footer .copyright .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      footer .copyright .row .col-xs-6 {
        text-align: center; } }

@media (max-width: 560px) {
  .whe-projects .col-xs-12, .whe-projects .container {
    padding: 0; }
  .slider-images .description {
    width: 100%;
    margin-top: 0;
    padding: 15px; }
  .steps-title {
    width: 100%;
    display: flex;
    align-items: center; }
    .steps-title .count {
      width: 40%; }
    .steps-title .title {
      font-size: 25px; }
      .steps-title .title .big {
        font-size: 25px;
        margin: 0; }
  .about-company .numbers .item .item-num {
    width: 140px;
    height: 140px;
    background-size: 100%; } }

@media (max-width: 480px) {
  .row {
    margin: 0;
    margin-right: 0;
    margin-left: 0; }
  .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-12 {
    padding: 0; }
  .header-box {
    position: relative;
    z-index: 5; }
  .first-slide {
    margin: -640px auto 0 !important; }
    .first-slide .content {
      font-size: 25px; }
  .form-slide .form-box-slide {
    width: 100%; }
  .form-slide .form {
    margin-top: -400px;
    padding: 15px; }
    .form-slide .form .form-group {
      margin-bottom: 10px; }
  header .info-box .row {
    flex-direction: column; }
    header .info-box .row .time-work-block, header .info-box .row .col-xs-5, header .info-box .row .col-xs-7 {
      width: 100%;
      padding: 0; }
    header .info-box .row .time-work-block {
      border: none;
      padding-bottom: 0; }
      header .info-box .row .time-work-block .css-arrow-right {
        display: none; }
      header .info-box .row .time-work-block .time-work {
        margin: 15px 0;
        text-align: center;
        font-size: 16px; }
        header .info-box .row .time-work-block .time-work span {
          margin: 0; }
  .two-slide .two-slide-wrap .slide {
    width: 100%; }
  .bg-fixed-2 {
    margin: -470px auto 0; } }

@media (max-width: 400px) {
  .bg-fixed-2 {
    margin: -400px auto 0; } }

.icon-site-room {
  background-position: 0 -629px;
  height: 71px;
  width: 71px; }

.icon-site-haus {
  background-position: 0 -213px;
  height: 71px;
  width: 107px; }

.icon-site-parking {
  background-position: 0 -543px;
  height: 71px;
  width: 73px; }

.icon-site-commerc {
  background-position: 0 -135px;
  height: 78px;
  width: 88px; }

.icon-site-arrow {
  background-position: 0 -79px;
  height: 56px;
  width: 35px; }

.icon-site-arrow-line {
  background-position: 0 -26px;
  height: 27px;
  width: 721px; }

.icon-site-hexagon-small {
  background-position: 0 -284px;
  height: 23px;
  width: 26px; }

.icon-site-hexagon {
  background-position: 0 -307px;
  height: 63px;
  width: 73px; }

.icon-site-line {
  background-position: 0 -370px;
  height: 70px;
  width: 306px; }

.icon-site-arrow-left {
  background-position: 0 0;
  height: 26px;
  width: 16px; }

.icon-site-arrow-right {
  background-position: 0 -53px;
  height: 26px;
  width: 16px; }

.icon-site-logo {
  background-position: 0 -440px;
  height: 103px;
  width: 477px; }

.icon-site-promicom {
  background-position: 0 -614px;
  height: 15px;
  width: 105px; }
